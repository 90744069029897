<script setup>
const props = defineProps({
    columnName: {
        type: [String, Number],
    },
    hideMobile: {
        type: Boolean,
        default: false,
    },
    hideContentMobile: {
        type: Boolean,
        default: false,
    },
    content: {
        type: String,
        default: '',
    },
});
</script>

<template>
    <td :class="{ 'hidden xl:table-cell': hideMobile }">
        <slot v-bind="props">
            <div
                class="flex items-center justify-between"
                :class="{ 'hidden lg:flex': hideContentMobile }"
            >
                {{ content }}
            </div>
        </slot>
    </td>
</template>
