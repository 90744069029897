<script setup>
const props = defineProps({
    hasOverflow: {
        type: Boolean,
        default: true,
    },
    columns: {
        type: Array,
    },
    rows: {
        type: Array,
    },
    sort: {
        type: Object,
    },
});

defineEmits(['sorted']);

const slots = useSlots();

const columnKeys = computed(() => {
    return props.columns.map((column, index) => column.name ?? index);
});

const getRow = (row) => {
    return columnKeys.value.reduce((acc, key, index) => {
        const component = slots[key];

        const content = row[key] ?? row[index] ?? '';

        acc[key] = !component
            ? content
            : {
                  ...(content ?? {}),
                  component,
              };

        return acc;
    }, {});
};
</script>

<template>
    <div
        class="rounded-lg border border-slate-300"
        :class="{ 'overflow-x-auto': hasOverflow }"
    >
        <table class="table w-full">
            <thead>
                <tr>
                    <slot name="head" :columns="columns">
                        <TableHeaders
                            :columns="columns"
                            :sort="sort"
                            @sorted="$emit('sorted', $event)"
                        />
                    </slot>
                </tr>
            </thead>
            <tbody>
                <slot name="body" :rows="rows" :columns="columns">
                    <template
                        v-for="(row, index) in rows"
                        :key="`row-${index}`"
                    >
                        <TableRow
                            :id="row.key ?? index"
                            :row="getRow(row)"
                            :columns="columns"
                        />
                    </template>
                </slot>
            </tbody>
        </table>
    </div>
</template>
