<script setup>
const props = defineProps({
    hideMobile: {
        type: Boolean,
        default: false,
    },
    hideContentMobile: {
        type: Boolean,
        default: false,
    },
    sortable: {
        type: Boolean,
        default: false,
    },
    show: {
        type: [Boolean, Function],
        default: true,
    },
    tooltipInfo: {
        type: String,
    },
    sortingDirection: {
        type: String,
        validator: (value) => {
            return [null, 'asc', 'desc'].includes(value);
        },
    },
});

const emit = defineEmits(['update:sortingDirection']);

const hover = ref(false);

const shown = computed(() => {
    if (typeof props.show === 'function') {
        return props.show();
    }

    return props.show;
});

const cycleDirections = () => {
    if (!props.sortingDirection) {
        emit('update:sortingDirection', 'asc');
        return;
    }

    if (props.sortingDirection === 'asc') {
        emit('update:sortingDirection', 'desc');
        return;
    }

    emit('update:sortingDirection', null);
};
</script>

<template>
    <th v-if="shown" :class="{ 'hidden xl:table-cell': hideMobile }">
        <div
            class="flex items-center justify-between"
            :class="{ 'hidden lg:flex': hideContentMobile }"
            v-on="{
                click: sortable ? cycleDirections : () => {},
                mouseenter: () => (hover = true),
                mouseleave: () => (hover = false),
            }"
        >
            <slot />

            <button
                v-if="sortable"
                class="sortable"
                :class="{
                    active: !!sortingDirection,
                    hover,
                }"
            >
                <IconSort
                    :class="{
                        ascending:
                            !sortingDirection || sortingDirection === 'asc',
                        descending: sortingDirection === 'desc',
                    }"
                />
            </button>

            <CommonTooltip
                v-if="tooltipInfo"
                :title="tooltipInfo"
                class="inline-block"
            >
                <CommonButton
                    link
                    only-icon
                    xsmall
                    class="h-0 translate-y-1.5 text-white hover:text-white"
                >
                    <template #custom-icon>
                        <IconInfo class="w-5 h-5" />
                    </template>
                </CommonButton>
            </CommonTooltip>
        </div>
    </th>
</template>

<style scoped>
.sortable {
    @apply h-3.5 w-3.5 transition-none text-white opacity-60;
}

.ascending {
    @apply rotate-180 -scale-x-[1];
}

.descending {
    @apply scale-x-[1];
}

.active {
    @apply text-brand opacity-100;
}

.hover {
    @apply opacity-100;
}
</style>
