<script setup>
import typeOf from 'just-typeof';

const props = defineProps({
    columns: {
        type: Array,
        required: true,
    },
    sort: {
        type: Object,
    },
});

const emit = defineEmits(['update:sort', 'sorted']);

const sortables = computed(() => {
    return props.columns.filter((column) => column.sortable);
});

const sortKey = (column) => {
    return typeOf(column.sortable) === 'string' ? column.sortable : column.name;
};

const getSorting = () => {
    if (!sortables.value.length) {
        return {};
    }

    if (!props.sort || Object.keys(props.sort).length === 0) {
        return props.columns
            .filter((column) => column.sortable)
            .reduce((acc, column) => {
                const k = sortKey(column);
                acc[k] = column.sortingDirection;

                return acc;
            }, {});
    }

    return props.sort;
};

const sortMap = ref(getSorting());

watch(sortMap.value, (value) => {
    emit('update:sort', value);
    emit('sorted', value);
});
</script>

<template>
    <TableHeader
        v-for="(header, index) in columns"
        :key="header.id ? header.id : `header-${index}`"
        v-model:sorting-direction="sortMap[sortKey(header)]"
        :class="{
            ...(header.class ?? {}),
            'hover:cursor-pointer': header.sortable,
        }"
        :show="header.show ?? true"
        :sortable="!!header.sortable"
        :hide-mobile="header.hideMobile"
        :hide-content-mobile="header.hideContentMobile"
        :tooltip-info="header.tooltipInfo"
    >
        <template v-if="header.component">
            <component :is="header.component" v-bind="header.props ?? {}" />
        </template>
        <template v-else>
            <TableHeaderLabel :header="header" />
        </template>
    </TableHeader>
</template>
