<script>
import Cell from './Cell.vue';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
    },
    // emits: ['on:selected'],
    setup(props, _ctx) {
        const columnEntries = computed(() => {
            return props.columns.reduce(
                (acc, column, index) => [
                    ...acc,
                    [
                        column.name ?? index,
                        {
                            ...column,
                            name: column.name ?? index,
                        },
                    ],
                ],
                []
            );
        });

        return () => {
            return h(
                'tr',
                {},
                columnEntries.value.map(([k, column]) => {
                    const content = props.row[k];

                    if (content.component) {
                        const { component, ...props } = content;

                        return typeof content.component === 'function'
                            ? h(Cell, {}, () => [content.component(props)])
                            : h(Cell, {}, () => [
                                  h(content.component, { ...props }),
                              ]);
                    }

                    if (typeof content === 'string') {
                        return h(Cell, {
                            ...column,
                            content,
                        });
                    }

                    return h(Cell, {
                        ...column,
                        content: '',
                    });
                })
            );
        };
    },
};
</script>
